/** @jsxImportSource @emotion/react */

import React from "react";
import "./App.css";
import { css, Global } from "@emotion/react";
import kids from "./kelly-sikkema-FqqaJI9OxMI-unsplash.jpg";
import kidsBlur from "./kelly-sikkema-FqqaJI9OxMI-unsplash-blur.png";

const backgroundColor = "#f0f7f0";
const subheadColor = "#1A373B";
const breakpoints = [700, 700];
const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const App: React.FunctionComponent<{}> = () => (
  <>
    <Global
      styles={css`
        body {
          background-color: ${backgroundColor};
        }
      `}
    />
    <div
      css={{
        // left: 0,
        // right: 0,
        // width: "100%",
        height: "100vh",
        // overflow: "visible",
        contain: "layout",
        // transform: "translateZ(100)",
        // zIndex: -1,
      }}
    >
      <div
        css={{
          position: "absolute",
          left: "-50%",
          top: "-50%",
          width: "200%",
          height: "200%",
          opacity: 1.0,
          backgroundImage: `url(${kidsBlur})`,
          backgroundSize: "cover",
          backgroundPosition: "50% 50%",
          [mq[0]]: {
            opacity: 0.65,
          },
        }}
      />
      <div
        css={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
          backgroundImage: `url(${kids})`,
          backgroundSize: "cover",
          backgroundPosition: "35% 0%",
          [mq[0]]: {
            backgroundPosition: "50% 40%",
            borderRadius: "32px",
            top: 50,
            left: 50,
            right: 50,
            bottom: 50,
          },
        }}
      />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          top: "2em",
          right: "2em",
          bottom: "2em",
          left: "2em",
          position: "absolute",
        }}
      >
        <div css={{ flex: 1 }}></div>
        <h1
          css={{
            [mq[0]]: {
              fontSize: "800%",
            },
            fontSize: "20vmin",
            margin: 0,
            color: "#eee",
            textAlign: "center",
            // textShadow: ["0 0 30px #000"],
            mixBlendMode: "plus-lighter",
          }}
        >
          Nurture
        </h1>
        <div
          css={{
            fontSize: "150%",
            color: "#FFF",
            textAlign: "center",
            fontWeight: "normal",
            textShadow: ["0 0 30px #000"],
          }}
        >
          Well-Being for Young Learners and Families of Color
        </div>
        <div css={{ flex: 1 }}></div>
      </div>
    </div>
    <main
      css={{
        // margin: "0 auto",
        [mq[0]]: {
          fontSize: "150%",
        },
        ".inner-container": {
          padding: "0 2em",
          [mq[0]]: {
            padding: "0 50px",
          },
          maxWidth: 1000,
          margin: "0 auto",
        },
        "section h2": {
          [mq[0]]: {},
          fontSize: "200%",
          fontWeight: "normal",
          marginTop: "2em",
          color: subheadColor,
          textShadow: ["0 1px 2px #fff"],
          // borderTop: "1px solid rgba(0, 0, 0, 0.1)",
          // borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <section className="first" id="our-mission-and-vision">
        <div className="inner-container">
          <h2>Our Mission and Vision</h2>
          <p>
            Young learners from under-represented communities deserve the best
            opportunities to thrive academically and succeed in life.
          </p>
          <p>
            Nurture is dedicated to advancing{" "}
            <em>diversity, equity, inclusion and belonging</em> in{" "}
            <em>early childhood.</em> We promote resilience, socio-emotional
            well being and academic success for under-served students of color
            in preschool and kindergarten.{" "}
          </p>
          <p>
            Through partnerships with schools, we provide subsidized services
            that empower students and their families to excel academically,
            emotionally, and socially within their communities.
          </p>
        </div>
      </section>
      <section id="the-need">
        <div className="inner-container">
          <h2>The Need</h2>
          <p>
            Early childhood is a critical period of brain development--up to 90%
            of an individual’s brain develops during this time. Toxic stress and
            adverse experiences can interrupt and negatively impact this
            process, creating long-lasting cognitive, physical, and
            social-emotional challenges. Early intervention and enriching
            resources along with warmth, safety, predictability, and appropriate
            stimulation can buffer the impact of risks or adverse experiences
            during this period. These protective factors not only make it
            significantly more likely for the child to attain a college
            education and have positive outcomes for mental health and
            well-being but also establish the groundwork for individuals to
            navigate life's challenges with greater emotional well-being and
            stability.
          </p>
          <p>
            Many families of color experience economic and social stressors. In
            San Francisco, 60% of families with children ages 0 to 5 struggle to
            afford their basic needs, including child care. They also face
            barriers to care including lack of insurance, affordability, stigma,
            cultural mistrust, and lack of culturally responsive providers.
            Currently, BIPOC families are more likely to experience mental
            health challenges and health risk behaviors, and less likely to
            access care and treatment. Without appropriate attention, household
            stress can become toxic and impede healthy development. As such,
            there is a dire need for culturally-conscious support to foster
            resilience and promote well-being in historically underserved
            populations.
          </p>
        </div>
      </section>
      <section id="our-services">
        <div className="inner-container">
          <h2>Our Services</h2>
          <div
            css={{
              [mq[0]]: {
                fontSize: "80%",
              },
              ul: {
                listStyle: "none",
                padding: 0,
              },
              li: {
                margin: "0.5em 0",
              },
              h3: {
                marginTop: "2em",
              },
              [mq[1]]: {
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                columnGap: 40,
              },
            }}
          >
            <div className="first" css={{ paddingRight: 20 }}>
              <h3>Mental Health</h3>
              {/* <img
              css={{ maxWidth: "100%", maxHeight: "100%" }}
              src="https://images.squarespace-cdn.com/content/v1/65023fd151f0ba6a449ffc3c/e86f29a1-6c7f-43b0-a5b8-bd50c91df749/What-is-Play-Therapy-And-How-Does-it-Work.jpg"
            /> */}
              <ul>
                <li>Neurodevelopmental and neuropsychological evaluation</li>
                <li>Psychotherapy</li>
                <li>Social Emotional Learning Playgroups</li>
                <li>Parent Therapy and Family Therapy</li>
                <li>Consultation</li>
              </ul>
            </div>

            <div>
              <h3>Parent Coaching</h3>
              {/* <img
              css={{ maxWidth: "100%", maxHeight: "100%" }}
              src="https://images.squarespace-cdn.com/content/v1/65023fd151f0ba6a449ffc3c/e86f29a1-6c7f-43b0-a5b8-bd50c91df749/What-is-Play-Therapy-And-How-Does-it-Work.jpg"
            /> */}
              <ul>
                <li>Individualized consultations with families</li>
                <li>
                  Assist families to navigate SF Bay Area preschools and
                  elementary schools
                </li>
                <li>
                  Guide parents through preschool and kindergarten transitions
                </li>
                <li>
                  Create connections and social support networks within the
                  school community and off-campus
                </li>
              </ul>
            </div>

            <div>
              <h3>Mentoring</h3>
              {/* <img
              css={{ maxWidth: "100%", maxHeight: "100%" }}
              src="https://images.squarespace-cdn.com/content/v1/65023fd151f0ba6a449ffc3c/e86f29a1-6c7f-43b0-a5b8-bd50c91df749/What-is-Play-Therapy-And-How-Does-it-Work.jpg"
            /> */}
              <ul>
                <li>
                  Connect families with other parents or contacts within the
                  community
                </li>
                <li>
                  Create a support network of trusted families and community
                  members who share similar experiences
                </li>
                <li>
                  Share community resources to keep families well informed and
                  supported
                </li>
              </ul>
            </div>

            <div>
              <h3>Extracurricular Support</h3>
              {/* <img
              css={{ maxWidth: "100%", maxHeight: "100%" }}
              src="https://images.squarespace-cdn.com/content/v1/65023fd151f0ba6a449ffc3c/e86f29a1-6c7f-43b0-a5b8-bd50c91df749/What-is-Play-Therapy-And-How-Does-it-Work.jpg"
            /> */}
              <ul>
                <li>Share information on after-school and camp programs</li>
                <li>
                  Offer financial aid for school enrichmnent ativities like
                  sports, art, music, and summer camps
                </li>
                <li>
                  Work with families and schools to support students' academic
                  needs
                </li>
                <li>
                  Connect families with trusted tutors or therapists in the
                  community
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="our-team">
        <div className="inner-container">
          <h2>Our Team</h2>
        </div>
      </section>
    </main>
    <footer css={{ maxWidth: 1000, margin: "50px auto" }}>
      <hr css={{ opacity: 0.3 }} />
      <div css={{ opacity: 0.5 }}>
        <p>Nurture is a 501(c)3 nonprofit organization ID # 93-2284210.</p>
        <p>
          Thank you for supporting our mission to enhance socio-emotional
          well-being for BIPOC families.
        </p>
      </div>
    </footer>
  </>
);

export default App;
